import './styles/index.css'
import './styles/App.css'
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Velocity from "./pages/Velocity";
import Dash from "./pages/Dash";
import Capture from './pages/Capture';

export default function App() {
  return (
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Velocity />} />
          <Route path="dash" element={<Dash />} />
          <Route path="capture" element={<Capture />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(<React.StrictMode><App /></React.StrictMode>);
import './App.css'
import React from 'react';
import CapturePanel from '../components/CapturePanel/CapturePanel';

function Capture() {
    return (
        <div className="App">
            <CapturePanel />
        </div>
      );
}

export default Capture;
import '../../styles/Navbar.css';
import logo from '../../logo.svg';
import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className='Navbar'>
        <img src={logo} alt="UBC Thunderbirds" className="NavLogo"></img>
        <div className='NavDiv'>
            <Link to="/">Velocities</Link>
            <Link to="/capture">Capture</Link>
            <Link to="/dash">Server</Link>
        </div>
    </nav>
  );
}

export default Navbar;
import '../../styles/ControlPanel.css'
import React, { useState } from 'react';

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY
const CAPTURE_API_URL = process.env.REACT_APP_CAPTURE_API_URL

let piTunnelURL = ""

const ControlPanel = (props) => {

    const [serverStatus, setServerStatus] = useState(["Status: unknown"])
    const [piStatus, setPiStatus] = useState(["Status: unknown"])
    const [captureStatus, setCaptureStatus] = useState(["Status: unknown"])

    const [piBusy, setPiBusy] = useState(false)
    const [serverBusy, setServerBusy] = useState(false)
    const [captureBusy, setCaptureBusy] = useState(false)


    const appendPiStatus = (newStatus) => {
        setPiStatus((ps) => [...ps,  newStatus])
    }

    const appendServerStatus = (newStatus) => {
        setServerStatus((ss) => [...ss,  newStatus])
    }

    const appendCaptureStatus = (newStatus) => {
        setCaptureStatus((ss) => [...ss,  newStatus])
    }

    const checkServer = async () => {
        setServerBusy(true)
        setServerStatus(["Checking server status ..."])
        try {
            const serverResult = await fetch(API_URL + "/server/check").then(res => res.json())
            if (serverResult.instanceError !== "") {
                appendServerStatus('Instance error: ' + serverResult.instanceError)
            } else {
                appendServerStatus('Instance status: ' + serverResult.instanceStatus)
            }

            if (serverResult.serverError !== "") {
                appendServerStatus('Server error: ' + serverResult.serverError)
            } else {
                appendServerStatus('Server status: ' + serverResult.serverStatus)
            }
        } catch (error) {
            appendServerStatus("Error: " + error)
        } finally {
            setServerBusy(false)
        }
    }

    const startInstance = async () => {
        setServerBusy(true)
        setServerStatus(["Starting instance ..."])
        try {
            const serverResult = await fetch(API_URL + "/server/start", {method: "POST"}).then(res => res.json())
            if (serverResult.error) {
                appendServerStatus('Error: ' + serverResult.error)
            } else {
                appendServerStatus('Instance status: booting. Server should be up in about a minute.')
            }
        } catch (error) {
            appendServerStatus("Error: " + error)
        } finally {
            setServerBusy(false)
        }
    }

    const stopInstance = async () => {
        setServerBusy(true)
        setServerStatus(["Stopping instance ..."])
        try {
            const serverResult = await fetch(API_URL + "/server/stop", {method: "POST"}).then(res => res.json())
            if (serverResult.error) {
                appendServerStatus('Error: ' + serverResult.error)
            } else {
                appendServerStatus('Instance status: stopping. Wait about a minute before re-starting.')
            }
        } catch (error) {
            appendServerStatus("Error: " + error)
        } finally {
            setServerBusy(false)
        }
    }

    const getPiTunnelUrl = async () => {
        appendPiStatus("Obtaining connection to Raspberry Pi - this could take up to 15 sec ...")
        try {
            const urlResult = await fetch(API_URL + "/kvs/geturl", {headers: {"x-api-key": API_KEY}}).then(res => res.json())
            if (urlResult.error) {
                appendPiStatus("Error: " + urlResult.error)
                appendPiStatus("Please retry.")
            } else {
                piTunnelURL = urlResult.url
                appendPiStatus(`Connection established at ${piTunnelURL}.`)
            }
        } catch (error) {
            appendPiStatus("Error: " + error)
            setPiBusy(false)
        }
    }

    const checkKVS = async () => {
        setPiBusy(true)
        setPiStatus([])
        if (piTunnelURL === "") {
            await getPiTunnelUrl()
            if (piTunnelURL === "") {
                setPiBusy(false)
                return
            }
        }
        appendPiStatus("Checking stream status ...")
        try {
            const checkResult = await fetch(API_URL + "/kvs/check", {method: "POST", headers:{"x-api-key": API_KEY}, body: JSON.stringify({"url": piTunnelURL})})
                .then(res => res.json())
            const stdout = checkResult.stdout
            const stderr = checkResult.stderr
            if (stderr.length > 0) {
                if (stderr[0].includes("Unable to connect")) {
                    appendPiStatus("PiTunnel connection expired. Please retry.")
                    piTunnelURL = ""
                } else {
                    appendPiStatus("Error: " + stderr.join("\n"))
                }   
            } else {
                console.log(stdout)
                if (stdout.length > 0) {
                    appendPiStatus("Status: Stream running")
                } else {
                    appendPiStatus("Status: Stream stopped")
                }
            }
            setPiBusy(false)
                
        } catch (error) {
            appendPiStatus("Error: " + error)
            setPiBusy(false)
        }
    }

    const startKVS = async () => {
        setPiStatus([])
        setPiBusy(true)
        if (piTunnelURL === "") {
            await getPiTunnelUrl()
            if (piTunnelURL === "") {
                setPiBusy(false)
                return
            }
        }
        appendPiStatus("Starting stream ...")

        try {
            const checkResult = await fetch(API_URL + "/kvs/start", {method: "POST", headers:{"x-api-key": API_KEY}, body: JSON.stringify({"url": piTunnelURL})})
                .then(res => res.json())
            const stdout = checkResult.stdout
            const stderr = checkResult.stderr
            if (stderr.length > 0) {
                if (stderr[0].includes("Unable to connect")) {
                    appendPiStatus("PiTunnel connection expired. Please retry.")
                    piTunnelURL = ""
                } else {
                    appendPiStatus("Error:" + stderr.join("\n"))
                }   
            } else {
                if (stdout.length > 0) {
                    appendPiStatus("Status: Stream successfully started.")
                } else {
                    appendPiStatus("Status: No output received, stream might not have started.")
                }
            }
            setPiBusy(false)
                
        } catch (error) {
            appendPiStatus("Error: " + error)
            setPiBusy(false)
        }
    }

    const stopKVS = async () => {
        setPiStatus([])
        setPiBusy(true)
        if (piTunnelURL === "") {
            await getPiTunnelUrl()
            if (piTunnelURL === "") {
                setPiBusy(false)
                return
            }
        }
        appendPiStatus("Stopping stream ...")

        try {
            const checkResult = await fetch(API_URL + "/kvs/stop", {method: "POST", headers:{"x-api-key": API_KEY}, body: JSON.stringify({"url": piTunnelURL})})
                .then(res => res.json())
            const stdout = checkResult.stdout
            const stderr = checkResult.stderr
            if (stderr.length > 0) {
                if (stderr[0].includes("Unable to connect")) {
                    appendPiStatus("PiTunnel connection expired. Please retry.")
                    piTunnelURL = ""
                } else {
                    appendPiStatus("Error:" + stderr.join("\n"))
                }   
            } else {
                if (stdout.length === 0) {
                    appendPiStatus("Status: Stream successfully stopped.")
                } else {
                    appendPiStatus("Status: Unexpected output received. Stream might not have stopped.")
                }
            }
            setPiBusy(false)
                
        } catch (error) {
            appendPiStatus("Error: " + error)
            setPiBusy(false)
        }
    }

    const startCapture = async () => {
        setCaptureStatus(["Starting capture ..."])
        setCaptureBusy(true)
        const controller = new AbortController()
        try {
            setTimeout(() => controller.abort(), 5000)
            const result = await fetch(CAPTURE_API_URL + "/start_capturing_now?batch_size=30", {method: "POST", signal: controller.signal})
                .then(res => res.json())

            if (result.request_id) {
                appendCaptureStatus("Response: Capture started")
            } else if (result.msg && result.msg === "busy") {
                appendCaptureStatus("Response: Capture already running")
            } else if (result.msg) {
                appendCaptureStatus("Response: " + result.msg)
            } else {
                appendCaptureStatus("Unexpected response. Capture might not have started.")
            }
                
        } catch (error) {
            if (controller.signal.aborted) {
                appendCaptureStatus("Request timed out. Check if server is online.")
            } else {
                appendCaptureStatus("Error: " + error.message)
            }
        } finally {
            setCaptureBusy(false)
        }
    }

    const stopCapture = async () => {
        setCaptureStatus(["Stopping capture ..."])
        setCaptureBusy(true)
        const controller = new AbortController()
        try {
            setTimeout(() => controller.abort(), 5000)
            const result = await fetch(CAPTURE_API_URL + "/stop_capturing", {method: "POST", signal: controller.signal})
                .then(res => res.json())

            console.log(result)

            if (result.msg && result.msg === "OK") {
                appendCaptureStatus("Response: Capture stopped")
            } else if (result.msg)  {
                appendCaptureStatus("Response: " + result.msg)
            } else {
                appendCaptureStatus("Unexpected response. Capture might not have stopped.")
            }
                
        } catch (error) {
            if (controller.signal.aborted) {
                appendCaptureStatus("Request timed out. Check if server is online.")
            } else {
                appendCaptureStatus("Error: " + error.message)
            }
        } finally {
            setCaptureBusy(false)
        }
    }

    return (
        <div className="App">
            <div>
                <h2>Server</h2>
                <div className="display-linebreak">
                    {serverStatus.map( (s, idx) => (s.toLowerCase().includes("status:") ? <span key={idx}><b>{s+"\n"}</b></span> : <span key={idx}>{s+"\n"}</span>))}
                </div>
                <br/>
                <button className="panelButton" disabled={serverBusy} onClick={checkServer}>Check</button>
                <button className="panelButton" disabled={serverBusy} onClick={startInstance}>Start</button>
                <button className="panelButton" disabled={serverBusy} onClick={stopInstance}>Stop</button>
            </div>
            <div style={{marginTop:"40pt"}}>
                <h2>Kinesis Stream</h2>
                <div className="display-linebreak">
                    {piStatus.map( (s, idx) => (s.startsWith("Status:") ? <span key={idx}><b>{s+"\n"}</b></span> : <span key={idx}>{s+"\n"}</span>))}
                </div>
                <br/>
                <button className="panelButton" disabled={piBusy} onClick={checkKVS}>Check</button>
                <button className="panelButton" disabled={piBusy} onClick={startKVS}>Start</button>
                <button className="panelButton" disabled={piBusy} onClick={stopKVS}>Stop</button>
            </div>
            <div style={{marginTop:"40pt"}}>
                <h2>Serve Capture</h2>
                <div className="display-linebreak">
                    {captureStatus.map( (s, idx) => (s.startsWith("Response:") ? <span key={idx}><b>{s+"\n"}</b></span> : <span key={idx}>{s+"\n"}</span>))}
                </div>
                <br/>
                <button className="panelButton" disabled={captureBusy} onClick={startCapture}>Start</button>
                <button className="panelButton" disabled={captureBusy} onClick={stopCapture}>Stop</button>
            </div>
            
        </div>
    )

};

export default ControlPanel;
import './App.css'
import React from 'react';
import ServePanel from '../components/ServePanel/ServePanel';

function Velocity() {
    return (
        <div className="App">
          <header className="App-header">
            <ServePanel />
          </header>
        </div>
      );
}

export default Velocity;
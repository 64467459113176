const names = [
    "Calvin Hsu",
    "Conaire Taub",
    "Dawson Pratt",
    "Gavin Moes",
    "Jackie Lu",
    "James Vincett",
    "Jesse Umoren",
    "Jon Lockie",
    "Kieran Robinson-Dunning",
    "Logan Greves",
    "Mason Greves",
    "Nick Johnson",
    "Nick Taub",
    "Reeve Gingera",
    "Ryder Turner",
    "Toby Bergh"
];

export default names;
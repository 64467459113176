import './App.css';
import React from 'react';
import ControlPanel from '../components/ControlPanel/ControlPanel';

function Dash() {
    return (
        <div className='App'>
            <h1>Control Panel</h1>
            <ControlPanel/>
        </div>
    );
}

export default Dash;